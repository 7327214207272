.formItemLayout {
    width: 160px;
    height: 39px;
    margin-bottom: 24PX;
    line-height: 39px;
    color: '#000'
}

.uploadModal {
    width: 20%;
    margin-bottom: 24PX;
}

.labelContent {
    display: 'flex';
    align-items: 'center';
    margin-bottom: 24
}