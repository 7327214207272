.layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 888px;
    height: 100%;
    overflow: hidden;
}

.contentHeader {
		width:100%;
    padding: 16px;
    display: flex;
		flex-wrap:nowrap;
		align-items:center;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    /* justify-content: space-between; */
}

.headerItems {
    width: 160px;
    margin: 0 4px;
}

.contentBody {
    height: 100%;
    /* min-width: 960px; */
    /* padding: 24px; */
    overflow: auto;
}


.timelineWrapper {
    width: 100%;
    height: 100%;
}

.timelineHeaderWrapper {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    z-index: 999;
    background: #fff;
    overflow: hidden;
}

.timelineHeaderSider {
    flex: 0 0 240px;
    height: 100%;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.headerOuter {
    display: flex;
    flex: 1;
    height: 100%;
}

.timeLabel {
    width: calc(100%/24);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

.timeLabel12 {
    width: calc(100%/12);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

.timeLabel24 {
    width: calc(100%/24);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

@media screen and (min-width: 1280px) {
    .timeLabel::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }

    .timeLabel12::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }

    .timeLabel24::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }
}

.timelineSidebar {
    flex: 0 0 240px;
}

.sidebarRow {
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 500;
    /* border-left: 1px solid #ddd; */
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebarRow:nth-child(even) {
    background: #f3f3f3;
}

.timelineOuter {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
    height: 40px;
    position: relative;
}

.timelineOuter:nth-child(even) {
    background: #f3f3f3;
}

.inactiveTimeGrid {
    width: calc(100%/24);
    border-right: 1px dashed #ddd;
    height: 40px;
    line-height: 40px;
}

.timeGrid {
    width: calc(100%/24);
    border-right: 1px dashed #ddd;
    height: 40px;
    line-height: 40px;
}

.timeGrid:nth-child(odd) {
    border-right: 1px solid #dedede;
}

.timeGrid:last-child {
    border-right: 1px solid #ddd;
}

.meetingBlock {
    height: 40px;
    line-height: 40px;
    background: transparent;
    position: absolute;
    white-space: nowrap;
    z-index: 333;
    cursor: pointer;
}

.meetingBlock span {
    display: block;
    height: 24px;
    line-height: 24px;
    margin-top: 7px;
    padding-left: 6px;
    border-radius: 2px;
    background: #757575;
    overflow: hidden;
    text-overflow: ellipsis;
}

.timeGrid:hover {
    /* background: rgba(217, 76, 51, 0.3); */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 99;
    transition: 0.2 ease-in-out;
}

.currentTimeBar {
    position: absolute;
    height: 40px;
    width: 2px;
    background: red;
    z-index: 666;
}

.textEllipsis {
    text-overflow: ellipsis;
}

.item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.item div:nth-child(2) {
    color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width:1680px) and (min-width: 1921px) {
    .spaceSvg {
        width: 1440px;
        height: 1080px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .spaceSvg {
        width: 600px;
        height: 500px;
    }
}

@media screen and (max-width: 1280px) and (min-width: 1440px) {
    .spaceSvg {
        width: 800px;
        height: 600px;
    }
}

:global(.ant-tabs-content) {
    overflow: auto !important;
}
