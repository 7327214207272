.container {
  display: flex;
  padding: 40px 0 0 40px;
  height: 100%;
}

.container h1 {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 500;
  color: rgba(59, 69, 78);
}

.container h2 {
  padding-top: 40px;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: rgba(36, 42, 49);
}

.container p {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 400;
}

.contents {
  list-style: none;
  flex: 0 0 260px;
}

.contents li {
  padding: 8px 0;
}

.helpContent {
  flex: 1;
  padding-right: 40px;
  padding-bottom: 40px;
  overflow: auto;
}

.helpContent img {
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid #eee;
}

.primaryText {
  color: #ea3e23;
}