.list {
  width: 100%;
  height: 100%;
  background: #fff;
  /* border: 1px solid #e8e8e8; */
}

.noData {
  width: 100%;
  padding: 0 25px;
  line-height: 54px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
  display: flex;
  justify-content: center;
}

.loading {
  width: 100%;
  padding: 0 25px;
  padding-top: 40px;
  line-height: 54px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}

.item {
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  cursor: pointer !important;
  /* background: red; */
}

.item:hover {
  background: #f8f8f8;
}

.item:active {
  background: #f8f8f8;
}

.item span {
  flex: 0 0 auto;
  align-self: center;
}

.active {
  background: #f8f8f8;
}