
.catelog {
  font-size: 12px;
  color: #909090;
  margin: 0 0 5px 24px;
}

.menu {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  background: #fcfcfc;
  border-right: 1px solid #e8e8e8;
  padding-top: 20px;
}

.menu > div {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.menu>div {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.menuItem {
  height: 32px;
  line-height: 32px;
  flex: 0 0 auto;
  font-size: 12px;
  margin: 1px 0 1px 0;
  padding: 0;
  padding-left: 0;
  border-left: 5px solid transparent;
  color: #303030;
  text-decoration: none;
}

.menuItem :active {
  text-decoration: none;
}

.menuItem :hover {
  color: #ea3e23;
  text-decoration: none;
}

.menuItem i {
  font-size: 12px;
}

.menuItem span {
  font-size: 12px;
  vertical-align: middle;
  padding-left: 10px;
}

.active {
  border-left: 5px solid #ea3e23;
  background: #e8e8e8;
  text-decoration: none;
}
