.layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}

.sider {
  width: 100%;
  height: 100%;
  padding: 16px;
  background: #fcfcfc;
  border-right: 1px solid #e8e8e8;
  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 400px;
  height: 100%;
  background: #fff;
}

.contentHeader {
  /* margin: 0 2px; */
  padding: 12px 8px;
  border-bottom: 1px solid #e8e8e8;
}

.details {
  flex: 0 0 480px;
  background: #fcfcfc;
  border-left: 5px solid #e8e8e8;
}

.details>h1 {
  margin-top: 10px;
  font-size: 18px;
  color: #303030;
}

.table {
  flex: 1 1 auto;
}

.item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.item div:nth-child(2) {
  color: rgba(0, 0, 0, 0.3);
}

.tree {
  /* background: yellow; */
  width: 100%;
  margin: 15px 20px;
}

.tree ul {
  list-style: none;
  padding-left: 10px;
  margin: 2px;
}

.tree li {
  cursor: pointer;
}

.filterTitle {
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 4px;
}

:global(.ant-pagination) {
  float: left !important;
  /* padding-left: 16px; */
}