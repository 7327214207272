.descriptionCol {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 240px;
	}
	
	.modalItem {
    width: 20%;
    margin-bottom: 24PX;
}