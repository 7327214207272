.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}

.sider {
  height: 100%;
  overflow-y: auto;
  background: #fcfcfc;
}

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.menu {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  background: #fcfcfc;
  border-right: 1px solid #e8e8e8;
  padding-top: 20px;
}

.menu>div {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1 auto;
  height: auto;
  /* padding: 20px; */
  background: #fff;
  overflow: hidden;
}

.menuItem {
  height: 26px;
  line-height: 26px;
  flex: 0 0 auto;
  font-size: 12px;
  margin: 1px 20px 1px 0;
  padding: 0;
  padding-left: 20px;
  border-left: 5px solid transparent;
  color: #303030;
  text-decoration: none;
}

.menuItem :active {
  text-decoration: none;
}

.menuItem :hover {
  color: #ea3e23;
  text-decoration: none;
}

.menuItem i {
  font-size: 12px;
}

.menuItem span {
  font-size: 12px;
  vertical-align: middle;
  padding-left: 10px;
}

.active {
  border-left: 5px solid rgba(234, 62, 35, 0.55);
  background: #e8e8e8;
  text-decoration: none;
}

.divider {
  margin: 12px 0;
}

.catelog {
  font-size: 12px;
  color: #909090;
  margin: 0 0 5px 24px;
}

/* CompanyProfile */
.companyContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
}

.companyHeader {
  /* display: flex;
  flex-direction: column;
  border: 2px solid blue; */
}

.companyTitle {
  margin: 0 0 40px 0;
}

.companyTitle span {
  font-size: 20px;
  font-weight: bold;
}

.tableWrapper {
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.companyItem {
  margin: 20px 0 0 0;
}

.companyItem span {
  font-size: 14px;
  color: #303030;
  font-weight: bold;
}

.companyLogoPanel {
  display: flex;
  flex-direction: column;
}

.companyLogoPanel * {
  flex: 0 0 auto;
  margin: 5px 0;
}

.companyLogoPanel img {
  width: 200px;
  max-width: 200px;
  max-height: 52px;
}

.companyLogoPanel span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.formLabel {
  font-weight: bold;
}

.tableTitle {
  width: 100%;
  height: 100%;
  background: #e8e8e8;
}

.tableItem {
  display: block;
  color: #333;
  padding: 16px;
}

.listHeader :parent {
  background: red;
}

.mb2 {
  margin-bottom: 16px;
}

.memberList {
  display: flex;
  flex-direction: column;
}

.memberListHeader {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 12px 16px;
  border-bottom: 1px solid #e8e8e8;
}

.memberListContent {
  flex: 1;
  overflow-y: scroll;
}