.reportCards::-webkit-scrollbar {
    width: 4px;
    height: 100%;
    background-color: transparent;
}

.reportTable * {
    font-size: 12px;
}

.reportTable .ant-pagination .ant-table-pagination .mini {
    border: 1px solid red;
}

/* .reportCards ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
}  */