.container {
    padding: 40px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .container .tableWrapper {
    flex: 1;
    /* overflow-y: scroll; */
  }
  
  .deviceCol {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 160px;
  }