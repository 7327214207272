* {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

html,
body,
#root {
  width: 100%;
  /* min-width: 1280px; */
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  background: #f8f8f8;
}

a:link,
a:visited,
a:hover,
a:active,
a:focus {
  text-decoration: none !important;
}

.ant-collapse-content-box {
  margin: 0 !important;
  padding: 0 !important;
}

.ant-btn.ant-btn-primary.christmas:before {
  display: none !important;
}

.ant-tabs {
  display: flex;
  flex-direction: column;
}

.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.ant-tabs-tabpane .ant-tabs-tabpane-active {
  flex: 1 1;
}
/* .layout {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #f8f8f8 !important;
  overflow: hidden;
}

.header {
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  height: 48px;
  background: #373a3d;
  line-height: 0;
}

.sider {
  width: 240px;
  background: #f8f8f8;
  padding-top: 20px;
  overflow-y: auto;
}

.sider::-webkit-scrollbar {
  width: 0 !important;
}

:global(.ant-layout-sider-children) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.company {
  font-size: 16px;
  font-weight: bold;
  padding: 0 20px 20px 20px;
}

.content {
  flex: 1 1 auto;
  height: auto;
  min-width: 720px;
  background: #fff;
  border: none;
  box-shadow: rgba(31, 31, 31, 0.0470588) 0px 4px 6px 0px, rgba(31, 31, 31, 0.2) 0px 0px 2px 0px;
  border-radius: 3px;
  margin: 10px;
  overflow: hidden;
}

.header h1 {
  line-height: 48px;
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
  color: #fff;
}

.profile {
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  padding-right: 15px;
  width: 130px;
}

.profileIcon {
  font-size: 20px;
  color: #aaa;
}

.loginIcon svg {
  font-size: 18px !important;
} */

html,
body {
  height: 100%;
}

.Home_trigger__2kUZq {
  font-size: 18px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
  color: #001529;
}

.Home_trigger__2kUZq:hover {
  /* color: #1890ff; */
  color: #ea3e23;
}

.Home_logo__1jz26 {
  height: 48px;
  line-height: 48px;
  text-align: center;
  /* background: rgba(255, 255, 255, .2); */
  margin: 16px;
}

.Home_profileIcon__1UmfD {
  font-size: 22px;
  color: #001529;
}
/* .menu {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.menuItem {
  display: flex;
  align-items: center;
  height: 34px;
  line-height: 34px;
  flex: 0 0 auto;
  margin: 2px 0;
  padding: 0;
  padding-left: 20px;
  border-left: 5px solid transparent;
  color: #303030;
  text-decoration: none;
}

.menuItem :active {
  text-decoration: none;
}

.menuItem :hover {
  color: #ea3e23;
  text-decoration: none;
}

.menuItem i {
  font-size: 16px;
}

.menuItem span {
  font-size: 14px;
  vertical-align: middle;
  padding-left: 10px;
}

.active {
  border-left: 5px solid #ea3e23;
  background: #e8e8e8;
  text-decoration: none;
}

.divider {
  margin: 8px 0;
} */
.ant-menu-item {
  padding: 0 !important;
}

.ant-menu-item>a {
  padding-left: 24px;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 !important;
  margin: 0 !important;
}

.ant-menu-inline-collapsed > .ant-menu-item > a {
  padding-left: 30px;
}

.AppMenu_activeLink__2cuQH {
  /* width: 100%;
  height: 100%; */
  color: #fff !important;
  background: #ea3e23;
}
.MemberCard_roomInfoHeader__2zoVq {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

.MemberCard_container__3vloE {
  font-size: 14px;
  min-width: 400px;
}

.MemberCard_title__unQtN {
  margin-top: 10px;
  font-size: 20px;
  line-height: 2;
  color: #303030;
  padding: 0;
}
.Rooms_layout__233L0 {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 888px;
    height: 100%;
    overflow: hidden;
}

.Rooms_contentHeader__2WYOb {
		width:100%;
    padding: 16px;
    display: flex;
		flex-wrap:nowrap;
		align-items:center;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    /* justify-content: space-between; */
}

.Rooms_headerItems__5ntke {
    width: 160px;
    margin: 0 4px;
}

.Rooms_contentBody__2YXbc {
    height: 100%;
    /* min-width: 960px; */
    /* padding: 24px; */
    overflow: auto;
}


.Rooms_timelineWrapper__2-_cl {
    width: 100%;
    height: 100%;
}

.Rooms_timelineHeaderWrapper__iuTIW {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    z-index: 999;
    background: #fff;
    overflow: hidden;
}

.Rooms_timelineHeaderSider__HwzJS {
    flex: 0 0 240px;
    height: 100%;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.Rooms_headerOuter__1H-LM {
    display: flex;
    flex: 1 1;
    height: 100%;
}

.Rooms_timeLabel__37Xg8 {
    width: calc(100%/24);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

.Rooms_timeLabel12__2yt5M {
    width: calc(100%/12);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

.Rooms_timeLabel24__c32W6 {
    width: calc(100%/24);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

@media screen and (min-width: 1280px) {
    .Rooms_timeLabel__37Xg8::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }

    .Rooms_timeLabel12__2yt5M::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }

    .Rooms_timeLabel24__c32W6::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }
}

.Rooms_timelineSidebar__8BKSq {
    flex: 0 0 240px;
}

.Rooms_sidebarRow__3t-nw {
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 500;
    /* border-left: 1px solid #ddd; */
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Rooms_sidebarRow__3t-nw:nth-child(even) {
    background: #f3f3f3;
}

.Rooms_timelineOuter__3E-FY {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
    height: 40px;
    position: relative;
}

.Rooms_timelineOuter__3E-FY:nth-child(even) {
    background: #f3f3f3;
}

.Rooms_inactiveTimeGrid__2e-zC {
    width: calc(100%/24);
    border-right: 1px dashed #ddd;
    height: 40px;
    line-height: 40px;
}

.Rooms_timeGrid__2yI8I {
    width: calc(100%/24);
    border-right: 1px dashed #ddd;
    height: 40px;
    line-height: 40px;
}

.Rooms_timeGrid__2yI8I:nth-child(odd) {
    border-right: 1px solid #dedede;
}

.Rooms_timeGrid__2yI8I:last-child {
    border-right: 1px solid #ddd;
}

.Rooms_meetingBlock__2XlXY {
    height: 40px;
    line-height: 40px;
    background: transparent;
    position: absolute;
    white-space: nowrap;
    z-index: 333;
    cursor: pointer;
}

.Rooms_meetingBlock__2XlXY span {
    display: block;
    height: 24px;
    line-height: 24px;
    margin-top: 7px;
    padding-left: 6px;
    border-radius: 2px;
    background: #757575;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Rooms_timeGrid__2yI8I:hover {
    /* background: rgba(217, 76, 51, 0.3); */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 99;
    transition: 0.2 ease-in-out;
}

.Rooms_currentTimeBar__3_cSF {
    position: absolute;
    height: 40px;
    width: 2px;
    background: red;
    z-index: 666;
}

.Rooms_textEllipsis__3iqRJ {
    text-overflow: ellipsis;
}

.Rooms_item__1Nm1s {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.Rooms_item__1Nm1s div:nth-child(2) {
    color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width:1680px) and (min-width: 1921px) {
    .Rooms_spaceSvg__3DBsJ {
        width: 1440px;
        height: 1080px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .Rooms_spaceSvg__3DBsJ {
        width: 600px;
        height: 500px;
    }
}

@media screen and (max-width: 1280px) and (min-width: 1440px) {
    .Rooms_spaceSvg__3DBsJ {
        width: 800px;
        height: 600px;
    }
}

.ant-tabs-content {
    overflow: auto !important;
}

.Company_container__wK763 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Company_toolbar__3_uxe {
    height: 54px;
    background: #fff;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #dedede;
}

.Company_content__2WZAP {
    flex: 1 1 auto;
    background: #fff;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.Company_master__3UYeI {
    flex: 1 1 auto;
    background: #fff;
    display: flex;
}

.Company_details__1cR_M {
    flex: 0 0 auto;
    width: 460px;
    height: 100%;
    border-left: 5px solid #e8e8e8;
    padding: 10px 20px;
    overflow-y: auto;
}

.Company_toolbtn__1jXMS {
    line-height: 1.15;
    font-size: 14px;
    font-weight: bold;
}

.Company_table__22EUO {
    width: 100%;
}

.Company_item__1FIXP {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.Company_item__1FIXP div:nth-child(2) {
    color: rgba(0, 0, 0, 0.3);
}

.Company_loading__2WxNB {
    width: 100%;
    height: 100%;
    background: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Company_loading__2WxNB h1 {
    flex: 0 0 auto;
    color: #fff;
    font-size: 150px;
}

.Company_details__1cR_M h1 {
    font-size: 18px;
    line-height: 1.5;
    color: #303030;
    margin: 5px 0 0 0;
    padding: 0;
}

.Company_details__1cR_M h2 {
    line-height: 1.5;
    margin: 15px 0 5px 0;
    padding: 0;
    font-size: 16px;
    color: #303030;
}

.Company_details__1cR_M p {
    font-size: 14px;
    line-height: 1.8;
    margin: 0;
    padding: 0;
    color: #909090;
}

.Company_optional__r-0Du {
    color: #909090;
}

.Company_detailsHeader__2FGX8 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Company_detailsHeader__2FGX8 * {
    display: flex;
}

.ant-modal .ant-form-item-label {
    /* text-align: left; */
}

/* :global(.ant-modal .ant-row *) {
    font-size: 13px !important;
}

:global(.ant-modal-body) {
    padding: 4px 24px 16px;
} */
.Help_container__1WSoE {
  display: flex;
  padding: 40px 0 0 40px;
  height: 100%;
}

.Help_container__1WSoE h1 {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 500;
  color: rgba(59, 69, 78);
}

.Help_container__1WSoE h2 {
  padding-top: 40px;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: rgba(36, 42, 49);
}

.Help_container__1WSoE p {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 400;
}

.Help_contents__1L0Xb {
  list-style: none;
  flex: 0 0 260px;
}

.Help_contents__1L0Xb li {
  padding: 8px 0;
}

.Help_helpContent__3D_Nb {
  flex: 1 1;
  padding-right: 40px;
  padding-bottom: 40px;
  overflow: auto;
}

.Help_helpContent__3D_Nb img {
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid #eee;
}

.Help_primaryText__77Nww {
  color: #ea3e23;
}
.AlertLog_container__Xj9Vu {
    padding: 40px 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .AlertLog_container__Xj9Vu .AlertLog_tableWrapper__1pLic {
    flex: 1 1;
    /* overflow-y: scroll; */
  }
  
  .AlertLog_deviceCol__1--Zk {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 160px;
  }
.Log_container__25iOx {
  padding: 40px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Log_container__25iOx .Log_tableWrapper__1vttj {
  flex: 1 1;
  /* overflow-y: scroll; */
}

.Log_deviceCol__35R3U {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 160px;
}
.Contact_layout__2lnrt {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}

.Contact_sider__2Mc04 {
  width: 100%;
  height: 100%;
  padding: 16px;
  background: #fcfcfc;
  border-right: 1px solid #e8e8e8;
  overflow: auto;
}

.Contact_content__g-fS4 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 400px;
  height: 100%;
  background: #fff;
}

.Contact_contentHeader__2unVq {
  /* margin: 0 2px; */
  padding: 12px 8px;
  border-bottom: 1px solid #e8e8e8;
}

.Contact_details__1HlpP {
  flex: 0 0 480px;
  background: #fcfcfc;
  border-left: 5px solid #e8e8e8;
}

.Contact_details__1HlpP>h1 {
  margin-top: 10px;
  font-size: 18px;
  color: #303030;
}

.Contact_table__2LHuI {
  flex: 1 1 auto;
}

.Contact_item__3ZgHr {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.Contact_item__3ZgHr div:nth-child(2) {
  color: rgba(0, 0, 0, 0.3);
}

.Contact_tree__1oJzw {
  /* background: yellow; */
  width: 100%;
  margin: 15px 20px;
}

.Contact_tree__1oJzw ul {
  list-style: none;
  padding-left: 10px;
  margin: 2px;
}

.Contact_tree__1oJzw li {
  cursor: pointer;
}

.Contact_filterTitle__1puTX {
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 4px;
}

.ant-pagination {
  float: left !important;
  /* padding-left: 16px; */
}
.List_list__3DXSZ {
  width: 100%;
  height: 100%;
  background: #fff;
  /* border: 1px solid #e8e8e8; */
}

.List_noData__2_R_0 {
  width: 100%;
  padding: 0 25px;
  line-height: 54px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
  display: flex;
  justify-content: center;
}

.List_loading__31Gr0 {
  width: 100%;
  padding: 0 25px;
  padding-top: 40px;
  line-height: 54px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}

.List_item__2QoDA {
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  cursor: pointer !important;
  /* background: red; */
}

.List_item__2QoDA:hover {
  background: #f8f8f8;
}

.List_item__2QoDA:active {
  background: #f8f8f8;
}

.List_item__2QoDA span {
  flex: 0 0 auto;
  align-self: center;
}

.List_active__15V3I {
  background: #f8f8f8;
}

.SiderMenu_catelog__EImL2 {
  font-size: 12px;
  color: #909090;
  margin: 0 0 5px 24px;
}

.SiderMenu_menu__3RCiz {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  background: #fcfcfc;
  border-right: 1px solid #e8e8e8;
  padding-top: 20px;
}

.SiderMenu_menu__3RCiz > div {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.SiderMenu_menu__3RCiz>div {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.SiderMenu_menuItem__2Argx {
  height: 32px;
  line-height: 32px;
  flex: 0 0 auto;
  font-size: 12px;
  margin: 1px 0 1px 0;
  padding: 0;
  padding-left: 0;
  border-left: 5px solid transparent;
  color: #303030;
  text-decoration: none;
}

.SiderMenu_menuItem__2Argx :active {
  text-decoration: none;
}

.SiderMenu_menuItem__2Argx :hover {
  color: #ea3e23;
  text-decoration: none;
}

.SiderMenu_menuItem__2Argx i {
  font-size: 12px;
}

.SiderMenu_menuItem__2Argx span {
  font-size: 12px;
  vertical-align: middle;
  padding-left: 10px;
}

.SiderMenu_active__42IVN {
  border-left: 5px solid #ea3e23;
  background: #e8e8e8;
  text-decoration: none;
}

* {}

.Profile_container__2uQQj {
  margin: 20px;
}

.Profile_title__2ILnv {
  margin: 0 0 30px 0;
}

.Profile_title__2ILnv span {
  font-size: 20px;
  font-weight: bold;
}

.Profile_item__1t8HZ {
  margin: 20px 0 0 0;
}

.Profile_item__1t8HZ span {
  font-size: 14px;
}

input:-moz-read-only {
  /* background: #f8f8f8; */
}

input:read-only {
  /* background: #f8f8f8; */
}
.Setting_layout__1Kmol {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}

.Setting_sider__2X-0R {
  height: 100%;
  overflow-y: auto;
  background: #fcfcfc;
}

.Setting_container__1LlKF {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.Setting_menu__2JzWl {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  background: #fcfcfc;
  border-right: 1px solid #e8e8e8;
  padding-top: 20px;
}

.Setting_menu__2JzWl>div {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.Setting_content__23puw {
  flex: 1 1 auto;
  height: auto;
  /* padding: 20px; */
  background: #fff;
  overflow: hidden;
}

.Setting_menuItem__390Ok {
  height: 26px;
  line-height: 26px;
  flex: 0 0 auto;
  font-size: 12px;
  margin: 1px 20px 1px 0;
  padding: 0;
  padding-left: 20px;
  border-left: 5px solid transparent;
  color: #303030;
  text-decoration: none;
}

.Setting_menuItem__390Ok :active {
  text-decoration: none;
}

.Setting_menuItem__390Ok :hover {
  color: #ea3e23;
  text-decoration: none;
}

.Setting_menuItem__390Ok i {
  font-size: 12px;
}

.Setting_menuItem__390Ok span {
  font-size: 12px;
  vertical-align: middle;
  padding-left: 10px;
}

.Setting_active__3WuiF {
  border-left: 5px solid rgba(234, 62, 35, 0.55);
  background: #e8e8e8;
  text-decoration: none;
}

.Setting_divider__6j0Xs {
  margin: 12px 0;
}

.Setting_catelog__2FodD {
  font-size: 12px;
  color: #909090;
  margin: 0 0 5px 24px;
}

/* CompanyProfile */
.Setting_companyContainer__2XGnR {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
}

.Setting_companyHeader__2XtqE {
  /* display: flex;
  flex-direction: column;
  border: 2px solid blue; */
}

.Setting_companyTitle__1_Yh6 {
  margin: 0 0 40px 0;
}

.Setting_companyTitle__1_Yh6 span {
  font-size: 20px;
  font-weight: bold;
}

.Setting_tableWrapper__gP2qb {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.Setting_companyItem__D6E3- {
  margin: 20px 0 0 0;
}

.Setting_companyItem__D6E3- span {
  font-size: 14px;
  color: #303030;
  font-weight: bold;
}

.Setting_companyLogoPanel__LOjqy {
  display: flex;
  flex-direction: column;
}

.Setting_companyLogoPanel__LOjqy * {
  flex: 0 0 auto;
  margin: 5px 0;
}

.Setting_companyLogoPanel__LOjqy img {
  width: 200px;
  max-width: 200px;
  max-height: 52px;
}

.Setting_companyLogoPanel__LOjqy span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.Setting_formLabel__31LdQ {
  font-weight: bold;
}

.Setting_tableTitle__3L0ey {
  width: 100%;
  height: 100%;
  background: #e8e8e8;
}

.Setting_tableItem__3uwAW {
  display: block;
  color: #333;
  padding: 16px;
}

.Setting_listHeader__1BZSC :parent {
  background: red;
}

.Setting_mb2__3y6O0 {
  margin-bottom: 16px;
}

.Setting_memberList__228om {
  display: flex;
  flex-direction: column;
}

.Setting_memberListHeader__1njv7 {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 12px 16px;
  border-bottom: 1px solid #e8e8e8;
}

.Setting_memberListContent__2cEOA {
  flex: 1 1;
  overflow-y: scroll;
}
.Profile_container__y1ewK {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 20px 0 0 0;
}

.Profile_containerTitle__3F34s {
  margin: 0 0 40px 0;
}

.Profile_containerTitle__3F34s span {
  font-size: 20px;
  font-weight: bold;
}

.Profile_companyItem__10tHe {
  margin: 0 0 20px 10px;
  min-width: 480px;
  max-width: 640px;
}

.Profile_companyItem__10tHe span {
  font-size: 14px;
}

.Profile_formItemWrapper__20ZH0 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.Profile_formLabel__3_ZHI {
  display: inline-block;
  width: 320px;
  margin-right: 24px;
  color: #303030;
}

.Profile_tab__1iyh9 {
  padding: 10px 20px;
}
.Member_leftPane__1A3VV {
  /* padding: 10px 3px; */
  padding: 0;
  height: 100%;
  overflow: auto;
}

.Member_mainPane__RzD8Q {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Member_paneHeader__2Ds50 {
  width: 100%;
  flex: 0 0 auto;
  padding: 10px 0 0 0;
  background: #f8f8f8;
}

.Member_paneHeader__2Ds50 h1 {
  font-size: 20px;
  line-height: 1.5;
  padding-left: 15px;
  color: #383838;
}

.Member_paneBody__2tWmY {
  flex: 1 1 auto;
  height: 100%;
}

.Member_item__KYUv3 {
  padding-bottom: 20px;
}

.Member_container__1UP_5 {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Member_header__2Lmz8 {
  background: #f8f8f8;
  padding-right: 20px;
}

.Member_header__2Lmz8 h1 {
  font-size: 20px;
  line-height: 1.2;
  padding: 20px 0 10px 14px;
}

.Member_content__FA37i {
  height: 100%;
  overflow: hidden;
}
.Room_leftPane__3vVhU {
  /* padding: 10px 3px; */
  padding: 0;
  height: 100%;
  overflow: auto;
}

.Room_mainPane__zIaYe {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Room_paneHeader__2pmyT {
  width: 100%;
  flex: 0 0 auto;
  padding: 10px 0 0 0;
  background: #f8f8f8;
}

.Room_paneHeader__2pmyT h1 {
  font-size: 20px;
  line-height: 1.5;
  padding-left: 15px;
  color: #383838;
}

.Room_paneBody__3Ps0O {
  flex: 1 1 auto;
  height: 100%;
}

.Room_item__GTgW- {
  padding-bottom: 20px;
}

.Room_container__7NfQG {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Room_header__2HliJ {
  background: #f8f8f8;
  padding-right: 20px;
}

.Room_header__2HliJ h1 {
  font-size: 20px;
  line-height: 1.2;
  padding: 20px 0 10px 14px;
}

.Room_content__2uWCn {
  height: 100%;
  overflow: hidden;
}

.Room_formItemWrapper__cXXBG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.Room_formLabel__100q6 {
  display: inline-block;
  width: 320px;
  margin-right: 24px;
  color: #303030;
}
.ReportChart_reportCards__GuPyu::-webkit-scrollbar {
    width: 4px;
    height: 100%;
    background-color: transparent;
}
.ReportChart_layout__9P8im {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 888px;
    height: 100%;
    overflow: hidden;
}

.ReportChart_contentHeader__1DTM2 {
    padding: 16px;
    display: flex;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    justify-content: space-between;
}

.ReportChart_headerItems__2FqNV {
    width: 160px;
    margin: 0 10px;
}

.ReportChart_contentBody__1pGJq {
    height: 100%;
    /* min-width: 960px; */
    /* padding: 24px; */
    overflow: auto;
}


.ReportChart_timelineWrapper__3duKY {
    width: 100%;
    height: 100%;
}

.ReportChart_timelineHeaderWrapper__7QHZy {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    z-index: 999;
    background: #fff;
    overflow: hidden;
}

.ReportChart_timelineHeaderSider__1Yi6h {
    flex: 0 0 240px;
    height: 100%;
    border: 1px solid #ddd;
    /* border-bottom: 1px solid #ddd; */
}

.ReportChart_headerOuter__2Jx-R {
    display: flex;
    flex: 1 1;
    height: 100%;
}

.ReportChart_timeLabel__2JLxb {
    width: calc(100%/24);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

.ReportChart_timeLabel12__1hos9 {
    width: calc(100%/12);
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

.ReportChart_timeLabel24__2PsyR {
    width: calc(100%/24);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

@media screen and (min-width: 1280px) {
    .ReportChart_timeLabel__2JLxb::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }

    .ReportChart_timeLabel12__1hos9::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }

    .ReportChart_timeLabel24__2PsyR::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }
}

.ReportChart_timelineSidebar__2X6r1 {
    flex: 0 0 240px;
}

.ReportChart_sidebarRow__1fVjT {
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 500;
    /* border-left: 1px solid #ddd; */
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ReportChart_sidebarRow__1fVjT:nth-child(even) {
    background: #f3f3f3;
}

.ReportChart_timelineOuter__1wGuQ {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
    height: 40px;
    position: relative;
}

.ReportChart_timelineOuter__1wGuQ:nth-child(even) {
    background: #f3f3f3;
}

.ReportChart_inactiveTimeGrid__2q7MN {
    width: calc(100%/24);
    border-right: 1px dashed #ddd;
    height: 40px;
    line-height: 40px;
}

.ReportChart_timeGrid__2-RPy {
    width: calc(100%/24);
    border-right: 1px dashed #ddd;
    height: 40px;
    line-height: 40px;
}

.ReportChart_timeGrid__2-RPy:nth-child(odd) {
    border-right: 1px solid #dedede;
}

.ReportChart_timeGrid__2-RPy:last-child {
    border-right: 1px solid #ddd;
}

.ReportChart_meetingBlock__2zVOW {
    height: 40px;
    line-height: 40px;
    background: transparent;
    position: absolute;
    white-space: nowrap;
    z-index: 333;
		cursor: pointer;
}

.ReportChart_meetingBlock__2zVOW span {
    display: block;
    height: 40px;
    line-height: 40px;
    border-radius: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ReportChart_timeGrid__2-RPy:hover {
    /* background: rgba(217, 76, 51, 0.3); */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 99;
    transition: 0.2 ease-in-out;
}

.ReportChart_currentTimeBar__2JJhd {
    position: absolute;
    height: 40px;
    width: 2px;
    background: red;
    z-index: 666;
}

.ReportChart_textEllipsis__1dj-r {
    text-overflow: ellipsis;
}

.ReportChart_item__1DM36 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.ReportChart_item__1DM36 div:nth-child(2) {
    color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width:1680px) and (min-width: 1921px) {
    .ReportChart_spaceSvg__1NRwN {
        width: 1440px;
        height: 1080px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .ReportChart_spaceSvg__1NRwN {
        width: 600px;
        height: 500px;
    }
}

@media screen and (max-width: 1280px) and (min-width: 1440px) {
    .ReportChart_spaceSvg__1NRwN {
        width: 800px;
        height: 600px;
    }
}

.ant-tabs-content {
    overflow: auto !important;
}

.Report_reportCards__1sqOy::-webkit-scrollbar {
    width: 4px;
    height: 100%;
    background-color: transparent;
}

.Report_reportTable__k13pX * {
    font-size: 12px;
}

.Report_reportTable__k13pX .Report_ant-pagination__3VuTB .Report_ant-table-pagination__29x4- .Report_mini__25FNO {
    border: 1px solid red;
}

/* .reportCards ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #555;
}  */
.issue_commentBtn__33PKV {
    color: #333;
    font-size: 18px;
    margin-bottom: 16px;
}

.issue_commentBtn__33PKV:hover {
    color: #ea3e23;
    transition: ease-in-out 0.2;
}
.roomboard_formItemLayout__2847P {
    width: 160px;
    height: 39px;
    margin-bottom: 24PX;
    line-height: 39px;
    color: '#000'
}

.roomboard_uploadModal__2XUnY {
    width: 20%;
    margin-bottom: 24PX;
}

.roomboard_labelContent__28IW4 {
    display: 'flex';
    align-items: 'center';
    margin-bottom: 24
}
.Devices_descriptionCol__2_gIv {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 240px;
	}
	
	.Devices_modalItem__10XQu {
    width: 20%;
    margin-bottom: 24PX;
}
.desks_layout__3RJe8 {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 888px;
    height: 100%;
    overflow: hidden;
}

.desks_contentHeader__1cTj_ {
    padding: 16px;
    display: flex;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    justify-content: space-between;
}

.desks_headerItems__qQUfT {
    width: 160px;
    margin: 0 10px;
}

.desks_contentBody__n913J {
    height: 100%;
    /* min-width: 960px; */
    /* padding: 24px; */
    overflow: auto;
}


.desks_timelineWrapper__vurTM {
    width: 100%;
    height: 100%;
}

.desks_timelineHeaderWrapper__12mF2 {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    z-index: 999;
    background: #fff;
    overflow: hidden;
}

.desks_timelineHeaderSider__3jkpt {
    flex: 0 0 240px;
    height: 100%;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.desks_headerOuter__KLNh0 {
    display: flex;
    flex: 1 1;
    height: 100%;
}

.desks_timeLabel__s5B-y {
    width: calc(100%/24);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

.desks_timeLabel12__1hYt- {
    width: calc(100%/12);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

.desks_timeLabel24__2QJIY {
    width: calc(100%/24);
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    /* padding-left: 8px; */
    line-height: 40px;
    font-weight: 500;
}

@media screen and (min-width: 1280px) {
    .desks_timeLabel__s5B-y::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }

    .desks_timeLabel12__1hYt-::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }

    .desks_timeLabel24__2QJIY::after {
        content: ':00';
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
    }
}

.desks_timelineSidebar__1WiWV {
    flex: 0 0 240px;
}

.desks_sidebarRow__1PKPt {
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 500;
    /* border-left: 1px solid #ddd; */
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.desks_sidebarRow__1PKPt:nth-child(even) {
    background: #f3f3f3;
}

.desks_timelineOuter__1QK53 {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
    height: 40px;
    position: relative;
}

.desks_timelineOuter__1QK53:nth-child(even) {
    background: #f3f3f3;
}

.desks_inactiveTimeGrid__Lu5pH {
    width: calc(100%/24);
    border-right: 1px dashed #ddd;
    height: 40px;
    line-height: 40px;
}

.desks_timeGrid__3ndWJ {
    width: calc(100%/24);
    border-right: 1px dashed #ddd;
    height: 40px;
    line-height: 40px;
}

.desks_timeGrid__3ndWJ:nth-child(odd) {
    border-right: 1px solid #dedede;
}

.desks_timeGrid__3ndWJ:last-child {
    border-right: 1px solid #ddd;
}

.desks_meetingBlock__3COMO {
    height: 40px;
    line-height: 40px;
    background: transparent;
    position: absolute;
    white-space: nowrap;
    z-index: 333;
    cursor: pointer;
}

.desks_meetingBlock__3COMO span {
    display: block;
    height: 24px;
    line-height: 24px;
    margin-top: 7px;
    padding-left: 6px;
    border-radius: 2px;
    background: #757575;
    overflow: hidden;
    text-overflow: ellipsis;
}

.desks_timeGrid__3ndWJ:hover {
    /* background: rgba(217, 76, 51, 0.3); */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 99;
    transition: 0.2 ease-in-out;
}

.desks_currentTimeBar__3xG8r {
    position: absolute;
    height: 40px;
    width: 2px;
    background: red;
    z-index: 666;
}

.desks_textEllipsis__UAtTO {
    text-overflow: ellipsis;
}

.desks_item__A7u8i {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.desks_item__A7u8i div:nth-child(2) {
    color: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width:1680px) and (min-width: 1921px) {
    .desks_spaceSvg__3CxpM {
        width: 1440px;
        height: 1080px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .desks_spaceSvg__3CxpM {
        width: 600px;
        height: 500px;
    }
}

@media screen and (max-width: 1280px) and (min-width: 1440px) {
    .desks_spaceSvg__3CxpM {
        width: 800px;
        height: 600px;
    }
}

.ant-tabs-content {
    overflow: auto !important;
}
.Login_layout__3_tXB {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.Login_loginFormMain__SHlbl {
    width: 480px;
    margin: 0 auto;
    margin-top: 112px;
    padding: 40px 8px 72px;
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0 4px 6px 0 rgba(31, 31, 31, 0.05), 0 0 2px 0 rgba(31, 31, 31, 0.2)
}

.Login_formLogo__3U0Fe {
    margin-bottom: 24px;
    text-align: center;
}

.Login_formTitle__3jWCo {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
}

.Login_loginForm__2mwKt {
    max-width: 400px;
    margin: 25px 40px 40px 40px;
}

.Login_formContent__2LRjJ {
    margin-top: 36px;
}

.Login_loading__11BDu {
    width: 100%;
    height: 100%;
    background: #f8f8f8;
    display: flex;
    justify-content:center;
    align-items: center;
}

.Login_loading__11BDu h1 {
    flex: 0 0 auto;
    color: #fff;
    font-size: 150px;
}

.Login_lost__25HUX {
    width: 100%;
    height: 80%;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.Login_lost__25HUX h1 {
    flex: 0 0 auto;
    color: #777;
    font-size: 50px;
}

.Login_sso__3FjkO {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.Login_loginType__1GdDY {
    margin: 15px 0;
}
