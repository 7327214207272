/* .layout {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #f8f8f8 !important;
  overflow: hidden;
}

.header {
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  height: 48px;
  background: #373a3d;
  line-height: 0;
}

.sider {
  width: 240px;
  background: #f8f8f8;
  padding-top: 20px;
  overflow-y: auto;
}

.sider::-webkit-scrollbar {
  width: 0 !important;
}

:global(.ant-layout-sider-children) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.company {
  font-size: 16px;
  font-weight: bold;
  padding: 0 20px 20px 20px;
}

.content {
  flex: 1 1 auto;
  height: auto;
  min-width: 720px;
  background: #fff;
  border: none;
  box-shadow: rgba(31, 31, 31, 0.0470588) 0px 4px 6px 0px, rgba(31, 31, 31, 0.2) 0px 0px 2px 0px;
  border-radius: 3px;
  margin: 10px;
  overflow: hidden;
}

.header h1 {
  line-height: 48px;
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
  color: #fff;
}

.profile {
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  padding-right: 15px;
  width: 130px;
}

.profileIcon {
  font-size: 20px;
  color: #aaa;
}

.loginIcon svg {
  font-size: 18px !important;
} */

html,
body {
  height: 100%;
}

.trigger {
  font-size: 18px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
  color: #001529;
}

.trigger:hover {
  /* color: #1890ff; */
  color: #ea3e23;
}

.logo {
  height: 48px;
  line-height: 48px;
  text-align: center;
  /* background: rgba(255, 255, 255, .2); */
  margin: 16px;
}

.profileIcon {
  font-size: 22px;
  color: #001529;
}