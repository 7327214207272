.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 20px 0 0 0;
}

.containerTitle {
  margin: 0 0 40px 0;
}

.containerTitle span {
  font-size: 20px;
  font-weight: bold;
}

.companyItem {
  margin: 0 0 20px 10px;
  min-width: 480px;
  max-width: 640px;
}

.companyItem span {
  font-size: 14px;
}

.formItemWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.formLabel {
  display: inline-block;
  width: 320px;
  margin-right: 24px;
  color: #303030;
}

.tab {
  padding: 10px 20px;
}