/* .menu {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.menuItem {
  display: flex;
  align-items: center;
  height: 34px;
  line-height: 34px;
  flex: 0 0 auto;
  margin: 2px 0;
  padding: 0;
  padding-left: 20px;
  border-left: 5px solid transparent;
  color: #303030;
  text-decoration: none;
}

.menuItem :active {
  text-decoration: none;
}

.menuItem :hover {
  color: #ea3e23;
  text-decoration: none;
}

.menuItem i {
  font-size: 16px;
}

.menuItem span {
  font-size: 14px;
  vertical-align: middle;
  padding-left: 10px;
}

.active {
  border-left: 5px solid #ea3e23;
  background: #e8e8e8;
  text-decoration: none;
}

.divider {
  margin: 8px 0;
} */
:global(.ant-menu-item) {
  padding: 0 !important;
}

:global(.ant-menu-item>a) {
  padding-left: 24px;
}

:global(.ant-menu-inline-collapsed > .ant-menu-item) {
  padding: 0 !important;
  margin: 0 !important;
}

:global(.ant-menu-inline-collapsed > .ant-menu-item > a) {
  padding-left: 30px;
}

.activeLink {
  /* width: 100%;
  height: 100%; */
  color: #fff !important;
  background: #ea3e23;
}