.roomInfoHeader {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

.container {
  font-size: 14px;
  min-width: 400px;
}

.title {
  margin-top: 10px;
  font-size: 20px;
  line-height: 2;
  color: #303030;
  padding: 0;
}