.layout {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.loginFormMain {
    width: 480px;
    margin: 0 auto;
    margin-top: 112px;
    padding: 40px 8px 72px;
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0 4px 6px 0 rgba(31, 31, 31, 0.05), 0 0 2px 0 rgba(31, 31, 31, 0.2)
}

.formLogo {
    margin-bottom: 24px;
    text-align: center;
}

.formTitle {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
}

.loginForm {
    max-width: 400px;
    margin: 25px 40px 40px 40px;
}

.formContent {
    margin-top: 36px;
}

.loading {
    width: 100%;
    height: 100%;
    background: #f8f8f8;
    display: flex;
    justify-content:center;
    align-items: center;
}

.loading h1 {
    flex: 0 0 auto;
    color: #fff;
    font-size: 150px;
}

.lost {
    width: 100%;
    height: 80%;
    background: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.lost h1 {
    flex: 0 0 auto;
    color: #777;
    font-size: 50px;
}

.sso {
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.loginType {
    margin: 15px 0;
}