* {}

.container {
  margin: 20px;
}

.title {
  margin: 0 0 30px 0;
}

.title span {
  font-size: 20px;
  font-weight: bold;
}

.item {
  margin: 20px 0 0 0;
}

.item span {
  font-size: 14px;
}

input:read-only {
  /* background: #f8f8f8; */
}