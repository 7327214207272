.leftPane {
  /* padding: 10px 3px; */
  padding: 0;
  height: 100%;
  overflow: auto;
}

.mainPane {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.paneHeader {
  width: 100%;
  flex: 0 0 auto;
  padding: 10px 0 0 0;
  background: #f8f8f8;
}

.paneHeader h1 {
  font-size: 20px;
  line-height: 1.5;
  padding-left: 15px;
  color: #383838;
}

.paneBody {
  flex: 1 1 auto;
  height: 100%;
}

.item {
  padding-bottom: 20px;
}

.container {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  background: #f8f8f8;
  padding-right: 20px;
}

.header h1 {
  font-size: 20px;
  line-height: 1.2;
  padding: 20px 0 10px 14px;
}

.content {
  height: 100%;
  overflow: hidden;
}

.formItemWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.formLabel {
  display: inline-block;
  width: 320px;
  margin-right: 24px;
  color: #303030;
}