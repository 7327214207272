.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.toolbar {
    height: 54px;
    background: #fff;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #dedede;
}

.content {
    flex: 1 1 auto;
    background: #fff;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.master {
    flex: 1 1 auto;
    background: #fff;
    display: flex;
}

.details {
    flex: 0 0 auto;
    width: 460px;
    height: 100%;
    border-left: 5px solid #e8e8e8;
    padding: 10px 20px;
    overflow-y: auto;
}

.toolbtn {
    line-height: 1.15;
    font-size: 14px;
    font-weight: bold;
}

.table {
    width: 100%;
}

.item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.item div:nth-child(2) {
    color: rgba(0, 0, 0, 0.3);
}

.loading {
    width: 100%;
    height: 100%;
    background: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading h1 {
    flex: 0 0 auto;
    color: #fff;
    font-size: 150px;
}

.details h1 {
    font-size: 18px;
    line-height: 1.5;
    color: #303030;
    margin: 5px 0 0 0;
    padding: 0;
}

.details h2 {
    line-height: 1.5;
    margin: 15px 0 5px 0;
    padding: 0;
    font-size: 16px;
    color: #303030;
}

.details p {
    font-size: 14px;
    line-height: 1.8;
    margin: 0;
    padding: 0;
    color: #909090;
}

.optional {
    color: #909090;
}

.detailsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.detailsHeader * {
    display: flex;
}

:global(.ant-modal .ant-form-item-label) {
    /* text-align: left; */
}

/* :global(.ant-modal .ant-row *) {
    font-size: 13px !important;
}

:global(.ant-modal-body) {
    padding: 4px 24px 16px;
} */