* {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

html,
body,
:global(#root) {
  width: 100%;
  /* min-width: 1280px; */
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  background: #f8f8f8;
}

a:link,
a:visited,
a:hover,
a:active,
a:focus {
  text-decoration: none !important;
}

.ant-collapse-content-box {
  margin: 0 !important;
  padding: 0 !important;
}

.ant-btn.ant-btn-primary.christmas:before {
  display: none !important;
}

.ant-tabs {
  display: flex;
  flex-direction: column;
}

.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ant-tabs-tabpane .ant-tabs-tabpane-active {
  flex: 1;
}